import React, {useEffect, useState} from 'react';
import './ArtistEdit.scss';
import store from "../../redux/store";
import Artist from "../../interfaces/Artist";
import {checkAuthErrorByCode} from "../../services/ApiService";
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import ArtistService from "../../services/ArtistService";
import {RequestResponse} from "../../types/RequestResponse";
import {Wording} from "../../enums/Wording";
import {cToast, ToastType} from "../../utils/CustomToaster";

const ArtistEdit = () => {
    const [newArtistFirstName, setNewArtistFirstName] = useState("");
    const [newArtistLastName, setNewArtistLastName] = useState("");
    const [newArtistGender, setNewArtistGender] = useState(false);
    const navigate = useNavigate();

    const [artistLisDisplay, setArtistListDisplay] = useState<any>(null);

    const activateArtist = async (artistId: number) => {
        const response: RequestResponse = await ArtistService.activateArtist(artistId);
        if (response.hasFailed && response.errorStatus) {
            cToast(Wording.TOAST_OUPS, ToastType.ERROR);
            return checkAuthErrorByCode(response.errorStatus, navigate);
        }
        cToast(Wording.TOAST_ARTIST_ACTIVATED, ToastType.SUCCESS);
        await refreshArtists();
    }

    const refreshArtists = async () => {
        const response: RequestResponse = await ArtistService.getArtists();
        if (response.hasFailed && response.errorStatus) return checkAuthErrorByCode(response.errorStatus, navigate);
        ArtistService.setArtists(response.data.result)
        displayArtist(store.getState().app.artists)
    }

    const deactivateArtist = async (artistId: number) => {
        const response: RequestResponse = await ArtistService.deactivateArtist(artistId);
        if (response.hasFailed && response.errorStatus) {
            cToast(Wording.TOAST_OUPS, ToastType.ERROR);
            return checkAuthErrorByCode(response.errorStatus, navigate);
        }
        cToast(Wording.TOAST_ARTIST_DEACTIVATED, ToastType.SUCCESS);
        await refreshArtists();
    }

    const displayArtist = (artists: Artist[]) => {
        let rows: JSX.Element[] = [];
        rows.push(
            <div key={`artist-details`} className={"artist-details"}>
                <div key={`prenom`} style={{textAlign: "center", fontWeight: "bold"}}>Prénom</div>
                <div key={`nom`} style={{textAlign: "center", fontWeight: "bold"}}>Nom</div>
                <div key={`actions`} style={{textAlign: "center", fontWeight: "bold"}}>Actions</div>
            </div>
        )
        for(const artist of artists){
            rows.push(
                <div key={`${artist.firstname}-${artist.lastname}-box`} className={"artist-details"}>
                    <div key={`${artist.firstname}-${artist.lastname}-firstname`}>{artist.firstname}</div>
                    <div key={`${artist.firstname}-${artist.lastname}-lastname`}>{artist.lastname}</div>
                    <div key={`${artist.firstname}-${artist.lastname}-buttons`}>
                        {artist.active ?
                            <button key={`${artist.firstname}-${artist.lastname}-desactiver`} style={{color: "red"}}
                                    className={"btn"} onClick={() => deactivateArtist(artist.id)}
                                    title={"Désactiver"}><FontAwesomeIcon icon={faTimes}/></button> :
                            <button key={`${artist.firstname}-${artist.lastname}-activer`} className={"btn"}
                                    style={{color: "green"}} onClick={() => activateArtist(artist.id)}
                                    title={"Activer"}><FontAwesomeIcon icon={faCheck}/></button>}
                    </div>
                </div>
            )
        }
        setArtistListDisplay(rows);
    }

    useEffect(() => {
        displayArtist(store.getState().app.artists)
    }, [])

    const createArtists = async () => {
        const response: RequestResponse = await ArtistService.createArtist(newArtistFirstName, newArtistLastName, newArtistGender);
        if (response.hasFailed && response.errorStatus) {
            cToast(Wording.TOAST_OUPS, ToastType.ERROR);
            return checkAuthErrorByCode(response.errorStatus, navigate)
        }
        cToast(Wording.TOAST_ARTIST_CREATED, ToastType.SUCCESS);
        await refreshArtists();
    }

    return (
        <div className={"form-container"}>
            <div className={"form-box"}>
                <h3>Ajouter un artiste</h3>

                <input
                    type="text"
                    value={newArtistFirstName}
                    onChange={(e: any) => setNewArtistFirstName(e.target.value)}
                    placeholder="Prénom"
                />
                <input
                    type="text"
                    value={newArtistLastName}
                    onChange={(e: any) => setNewArtistLastName(e.target.value)}
                    placeholder="Nom"
                />
                <div className={"radio-groups"}>
                    <div className={"radio-group"}>
                        <input
                            checked={!newArtistGender}
                            onChange={() => true}
                            onClick={() => setNewArtistGender(false)}
                            type="radio"
                            id="genderFalse"
                            name="gender"
                        />
                        <label className="radio-label" htmlFor="genderFalse">
                            <div className="indicator"/>
                            <span className="radio-text">Femme</span>
                        </label>
                    </div>
                    <div className={"radio-group"}>
                        <input
                            checked={newArtistGender}
                            onChange={() => true}
                            onClick={() => setNewArtistGender(true)}
                            type="radio"
                            id="genderTrue"
                            name="gender"
                        />
                        <label className="radio-label" htmlFor="genderTrue">
                            <div className="indicator"/>
                            <span className="radio-text">Homme</span>
                        </label>
                    </div>
                </div>

                <button className={"btn"} onClick={createArtists}>Envoyer</button>
            </div>
            <div className={"artist-list"}>
                {artistLisDisplay}
            </div>
        </div>
    )
}

export default ArtistEdit;