import React, {FC, useEffect, useState} from 'react';
import './Nav.scss';
import {Link, useNavigate} from "react-router-dom";
import Artist from "../../interfaces/Artist";
import {useSelector} from "react-redux";
import {stringToColour} from "../../utils/uiUtils";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import store from "../../redux/store";
import moment from "moment";
import authService from "../../services/AuthService";
import {RouteList} from "../../enums/RouteList";

const Nav: FC<any> = _ => {
    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const [currentArtist, setCurrentArtist] = useState<Artist | null>();
    const a = useSelector((state: any) => state.app.currentArtist);
    const w = useSelector((state:any) => state.week.weekStart)
    let navigate = useNavigate();
    useEffect(() => {
        if (a) setCurrentArtist(a)
    }, [a])

    return (
        <div className="header disableTextSelection">
            <div className="logo-nav">
                <div className="logo-container">
                    {a &&
                    <>
                        <div className={`logo nav-avatar`}
                             onClick={() => navigate('/artists')}
                             style={{backgroundColor: stringToColour(a.firstname + a.lastname)}}>
                            {a.gender ? <img alt={"profile"} src={require("../../assets/man.png")}/> :
                                <img alt={"profile"} src={require("../../assets/woman.png")}/>}
                        </div>
                        <div onClick={() => navigate('/artists')} className={"nav-avatar-label"}
                             style={{backgroundColor: stringToColour(a.firstname + a.lastname)}}>{currentArtist?.firstname}</div>
                        <div className={"nav-avatar-label"}
                             style={{backgroundColor: stringToColour(a.firstname + a.lastname)}}>{`${moment(w).format('MM/YY')} - Semaine ${moment(w).format('WW')}`}</div>
                    </>
                    }
                </div>

                <ul className={click ? "nav-options active" : "nav-options"}>

                    {authService.isAdminSession() && <li className="option" onClick={closeMobileMenu}>
                        <div className={`link-box`}>
                            <Link className={`nav-link`} to={`/${RouteList.EDIT_ARTISTS}`}>ARTISTES</Link>
                        </div>
                    </li>}

                    {authService.isAdminSession() && <li className="option" onClick={closeMobileMenu}>
                        <div className={`link-box`}>
                            <Link className={`nav-link`} to={`/${RouteList.EDIT_CREDENTIAL}`}>IDENTIFIANTS</Link>
                        </div>
                    </li>}

                    {a &&
                    <li className="option" onClick={closeMobileMenu}>
                        <div className={`link-box`}>
                            <Link className={`nav-link`} to="/">PLANNING</Link>
                        </div>
                    </li>}
                    {authService.isNotAdminSession() && <li className="option" onClick={closeMobileMenu}>
                        <div className={`link-box`}>
                            <Link className={`nav-link`} to={`/${RouteList.ARTISTS}`}>ARTISTES</Link>
                        </div>
                    </li>}
                    {store.getState().auth.accessToken &&
                    <li className="option" onClick={() => window.location.reload()}>
                        <div className={`link-box link-box-red`}>
                            <Link className={`nav-link`} to="/">DÉCONNEXION</Link>
                        </div>
                    </li>}
                </ul>
            </div>

            <div className="mobile-menu" onClick={handleClick}>
                {click ? (
                    <FontAwesomeIcon className="menu-icon" icon={faTimes}/>
                ) : (
                    <FontAwesomeIcon className="menu-icon" icon={faPlus}/>
                )}
            </div>
        </div>
    );
};

export default Nav;