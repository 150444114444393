import {API_ENDPOINTS_URI} from "./ApiService";
import {RequestResponse} from "../types/RequestResponse";
import {GetWeek} from "../interfaces/request/GetWeek";
import {WeekUnavailable} from "../interfaces/request/WeekUnavailable";
import store from "../redux/store";
import {setWorkingHours} from "../redux/reducers/weekSlice";
import api, {emptyRequestResponse} from "./api";

async function getWeek(request: GetWeek): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;

    return api.post(API_ENDPOINTS_URI.GET_WEEK, request)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })
}

async function setIndispo(request: WeekUnavailable): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;

    return api.post(API_ENDPOINTS_URI.SET_WEEK_INDISPO, request)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })
}


const WeekService = {
    getWeek,
    setIndispo,
    setHours : (hours : number[]) => store.dispatch(setWorkingHours(hours))
}

export default WeekService;