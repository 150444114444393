import {API_ENDPOINTS_URI} from "./ApiService";
import store from "../redux/store";
import {RequestResponse} from "../types/RequestResponse";
import Message from "../interfaces/Message";
import api, {emptyRequestResponse} from "./api";


async function getMessage(id: number): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;

    return api.get(API_ENDPOINTS_URI.GET_MESSAGE(id, store.getState().week.weekStart))
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })

}

async function postMessage(request: Message): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;

    return api.post(API_ENDPOINTS_URI.POST_MESSAGE, request)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })

}


const MessageService = {
    getMessage,
    postMessage
}

export default MessageService;