import {API_ENDPOINTS_URI} from "./ApiService";
import api from "./api";
import TokenService from "./TokenService";
import ArtistService from "./ArtistService";
import UserService from "./UserService";
import WeekService from "./WeekService";
import store from "../redux/store";
import {Permission} from "../enums/Permission";
import {Wording} from "../enums/Wording";
import {cToast, ToastType} from "../utils/CustomToaster";

export interface Login {
    login: string,
    password: string
}

export const loginRequest = async (logins: Login) => {
    try {
        const response = await api.post(API_ENDPOINTS_URI.AUTH_LOGIN, logins);
        if (response.data.accessToken) {
            TokenService.setAccessToken(response.data.accessToken);
            TokenService.setRefreshToken(response.data.refreshToken);
            if (response.data.user) UserService.setUser(response.data.user)

            api.get(API_ENDPOINTS_URI.GET_HOURS)
                .then((res: any) => WeekService.setHours(res.data.workingHours))
                .catch(_ => console.error("OUPS"));

            const artistRes = await api.get(API_ENDPOINTS_URI.GET_ARTISTS);
            if (artistRes.data) ArtistService.setArtists(artistRes.data.result);
        }
        return response.data
    } catch (e: any) {
        const response = e.response;

        switch (response.status) {
            case 401 :
                cToast(Wording.TOAST_WRONG_CREDENTIALS, ToastType.ERROR);
                break;
            case 400 :
                cToast(Wording.TOAST_MISSING_CREDENTIALS, ToastType.ERROR);
                break;
            case 500 :
                cToast(Wording.TOAST_OUPS, ToastType.ERROR);
                break;
        }
        return response;
    }
}
const isAdminSession = () => store.getState().auth.user.permission === Permission.admin;
const isNotAdminSession = () => store.getState().auth.user.permission !== Permission.admin;
const isSimulationSession = () => store.getState().auth.user.permission === Permission.simulation;
const isNotSimulationSession = () => store.getState().auth.user.permission !== Permission.simulation;


const AuthService = {
    checkLoginRequest: loginRequest,
    isAdminSession,
    isNotAdminSession,
    isSimulationSession,
    isNotSimulationSession
}

export default AuthService;