import React, {ChangeEvent, useState} from 'react';
import "./Login.css";
import authService, {loginRequest} from "../../services/AuthService";
import {useNavigate} from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import {faSignInAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RouteList} from "../../enums/RouteList";

const Login = (props: any) => {

    const [login, setLogin] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    let navigate = useNavigate();

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const res = await loginRequest({login, password})
        if (res.user && res.accessToken) {
            props.setToken(res.accessToken);
            navigate(authService.isAdminSession() ? RouteList.EDIT_ARTISTS : RouteList.ARTISTS);
        }
    }


    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            handleSubmit(e)
        }
    }

    return (
        <>
            <div className="login-container">
                <div className="brand-logo"><FontAwesomeIcon icon={faSignInAlt}/></div>
                <div className="brand-title">Bienvenue !</div>
                <div className="inputs">
                    <input
                        type="text"
                        className="form__input"
                        id="login"
                        placeholder="Identifiant"
                        value={login}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <input
                        type="password"
                        className="form__input"
                        style={{marginTop: "20px"}}
                        id="password"
                        placeholder="Mot de passe"
                        value={password}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    <button onClick={handleSubmit} type="submit">Connexion</button>
                </div>
            </div>
        </>

    )
}
export default Login;