import store from "../redux/store";
import {setAccessToken} from "../redux/reducers/authSlice";
import {RequestResponse} from "../types/RequestResponse";

export const API_ENDPOINTS_URI = {
    AUTH_LOGIN: `/auth/login`,
    AUTH_REFRESH_TOKEN: `/auth/refreshToken`,
    LOGIN_VERIFY: `/auth/verify`,
    GET_WEEK: `/appointment/week`,
    GET_ARTISTS: `/artist`,
    POST_APT: `/appointment/`,
    DELETE_APT: `/appointment/delete`,
    SYNC: `/appointment/sync`,
    GET_HOURS: `/week/hours`,
    SET_WEEK_INDISPO: `/week/indispo`,
    GET_USERNAMES: "/user/usernames",
    GET_BACKEND_VERSION: "/version",

    GET_MESSAGE: (artistId: number, dateStart: string) => `/message/${artistId}/${dateStart}`,
    POST_MESSAGE: `/message/update`,
    DEACTIVATE_ARTIST: (artistId: number) => `/artist/deactivate/${artistId}`,
    ACTIVATE_ARTIST: `/artist/activate`,
    CREATE_ARTIST: `/artist/create`,
    UPDATE_CREDENTIALS: `/user/credentials`,
}

export async function handleRequestError(error: any): Promise<RequestResponse> {
    return {
        hasFailed: true,
        errorMessage: error?.message,
        errorStatus: error?.response?.status,
    };
}

/**
 * Checks for authentication errors based on the provided error code and redirects the user to the login page if necessary.

 * @param {number} errorCode The HTTP error code received from the server.
 * @param {any} navigate A navigation function, typically provided by a routing library like React Router.
 */
export const checkAuthErrorByCode = async (errorCode: number, navigate: any,) => {
    if (errorCode === 403 || errorCode === 401) {
        store.dispatch(setAccessToken(""))
        navigate("/login")
    }
}