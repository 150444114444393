import React, {useEffect, useState} from 'react';
import './App.scss';
import Nav from "./components/Nav/Nav";
import {Route, Routes} from "react-router-dom";
import Login from "./pages/Login/Login";
import ArtistList from "./pages/ArtistList/ArtistList";
import Scheduler from "./pages/Scheduler/Scheduler";
import store from "./redux/store";
import Sync from "./components/Sync/Sync";
import ArtistEdit from "./pages/ArtistEdit/ArtistEdit";
import authService from "./services/AuthService";
import {RouteList} from "./enums/RouteList";
import packageJson from '../package.json';
import CredentialUpdate from "./pages/CredentialUpdate/CredentialUpdate";
import {RequestResponse} from "./types/RequestResponse";
import AppService from "./services/AppService";
import {setBackendVersion} from "./redux/reducers/appSlice";
import {useDispatch} from "react-redux";

function App() {
    const [_, setToken] = useState<any>();
    const dispatch = useDispatch();

    useEffect(() => {
        // @ts-ignore
        setToken(store.getState().auth.accessToken)
        setVersion().then(_ => console.log("Got version"));
    }, [])
    const setVersion = async () => {
        const response: RequestResponse = await AppService.getVersion();
        if (response.data) {
            dispatch(setBackendVersion(response.data));
        }
    }
    if (!store.getState().auth.accessToken) return <Login setToken={setToken}/>
    return (
        <>
            <Routes>
                <Route path="/login" element={<Login setToken={setToken}/>}/>
                {authService.isNotAdminSession() &&
                    <Route path={RouteList.HOMEPAGE} element={<><Nav/><Scheduler/></>}/>}
                {authService.isNotAdminSession() &&
                    <Route path={RouteList.ARTISTS} element={<><Nav/><ArtistList/></>}/>}
                {authService.isAdminSession() &&
                    <Route path={RouteList.EDIT_ARTISTS} element={<><Nav/><ArtistEdit/></>}/>}
                {authService.isAdminSession() &&
                    <Route path={RouteList.EDIT_CREDENTIAL} element={<><Nav/><CredentialUpdate/></>}/>}
                {authService.isNotSimulationSession() &&
                    <Route path={RouteList.SIMULATION} element={<><Nav/><Sync/></>}/>}
            </Routes>
            <p className={"appVersion disableTextSelection"}>
                <p>Agenda Feeling Tattoo</p>
                <p>v{packageJson.version} / v{store.getState().app.backendVersion}</p>
            </p>
        </>
    );
}

export default App;
