import {RequestResponse} from "../types/RequestResponse";
import api, {emptyRequestResponse} from "./api";
import {API_ENDPOINTS_URI} from "./ApiService";

async function getVersion(): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;
    return api.get(API_ENDPOINTS_URI.GET_BACKEND_VERSION)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })

}

const AppService = {
    getVersion
}

export default AppService;