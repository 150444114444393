import Appointment from "../../interfaces/Appointment";
import {AptType} from "../../enums/AptType";
import moment from "moment";
import store from "../../redux/store";
import {Week} from "../../types/Week";
import {CellPosition} from "./interfaces/CellPosition";
import {AppointmentCellPosition} from "./interfaces/AppointmentCellPosition";
import DateTool from "../../utils/DateTool";
import {ClientNameBackground} from "./enums/EClientNameBackground";
import WeekUtils, {DATE_FORMAT} from "../../utils/WeekUtils";

// Heures = 1; Lundi = 2; etc... Donc pour avoir l'index dans une semaine : -2
export const COLUMN_OFFSET_FOR_DAY_INDEX_IN_WEEK: number = 2;
// Il y a la ligne des jours. Donc 10h00 - 10h30 = 2; 10h30 - 11h00 = 2
export const ROW_INCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX: number = 2;
export const ROW_EXCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX: number = 1;

// TODO: test
const isLunchtime = (row: number) => {
    const workingHours = store.getState().week.workingHours;
    return row === workingHours.indexOf(12) + ROW_INCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX
        || row === workingHours.indexOf(13) + ROW_EXCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX
};

// TODO: test
const getAppointmentByColumnAndRow = (week: Week, column: number, startRow: number, endRow: number) => {
    const weekUtils = new WeekUtils();
    const dayIndex = column - COLUMN_OFFSET_FOR_DAY_INDEX_IN_WEEK;
    const start = store.getState().week.workingHours[startRow - COLUMN_OFFSET_FOR_DAY_INDEX_IN_WEEK]
    const end = store.getState().week.workingHours[endRow - 1]
    const dateStr = moment(week.days[dayIndex].timestamp).format(DATE_FORMAT);
    return {
        tattoo_artist_id: store.getState().app.currentArtist?.id,
        firstname: "",
        lastname: "",
        phone: "",
        date_start: `${dateStr} ${weekUtils.getStrHourForDB(start)}`, // Ex : "2022-12-22 10:00:00"
        date_end: `${dateStr} ${weekUtils.getStrHourForDB(end)}`,
        price: 0,
        arrhes: 0,
        pattern: "",
        comment: "",
        email: "",
        is_deleted: false,
        total_price: 0,
        duration: endRow - startRow,
        start,
        end,
    }
}

// TODO: test
const getCellPositionFromEvent = (e: any): CellPosition => {
    let cellPosition: CellPosition = {
        row: 0,
        column: 0,
    };

    if (e && e.target && e.target.style) {
        cellPosition = {
            row: +e.target.style["grid-row-start"],
            column: +e.target.style["grid-column-start"]
        }
    } else if (e && e.style) {
        cellPosition = {
            row: +e.style["grid-row-start"],
            column: +e.style["grid-column-start"]
        }
    }
    return cellPosition;
}

/**
 * Vérifie si un RDV peut être collé à une position
 * @param copiedAppointment
 * @param week
 * @param cellPosition
 * @param workingHours
 */
const isThereEnoughSpaceFor = (copiedAppointment: Appointment, week: Week, cellPosition: CellPosition, workingHours: number[]): boolean => {
    const selectedDay = week.days.find(day => moment(day.dateStr).weekday() === cellPosition.column - 1);
    if (!selectedDay) return false;

    const selectedDayAppointments: Appointment[] = selectedDay.appointments;

    const decimalStartOfAptToCreate = workingHours[cellPosition.row - ROW_INCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX];
    const nb0fHalfHours = DateTool.getNumberOfHalfHoursBetweenDates(copiedAppointment.date_start, copiedAppointment.date_end);
    const nb0fCells = nb0fHalfHours / 2;
    const decimalEndOfAptToCreate = decimalStartOfAptToCreate + nb0fCells;

    // Si l'heure de fin est en dehors des heures d'ouvertures par exemple
    if (!workingHours.includes(decimalStartOfAptToCreate) || !workingHours.includes(decimalEndOfAptToCreate)) return false;
    // Si aucun rdv n'est prévu pour la journée alors il y a de la place. Doit être fait après la vérification précédente
    if (selectedDayAppointments.length === 0) return true;

    for (const apt of selectedDayAppointments) {
        const currentAptDecimalStart = DateTool.getDecimalHourAndMinutes(apt.date_start);
        const currentAptDecimalEnd = DateTool.getDecimalHourAndMinutes(apt.date_end);
        const bothAptHasSameStart: boolean = currentAptDecimalStart === decimalStartOfAptToCreate;
        const bothAptHasSameEnd: boolean = currentAptDecimalEnd === decimalEndOfAptToCreate;
        const newAptEndIsAfterCurrentAptEnd: boolean = decimalEndOfAptToCreate > currentAptDecimalEnd;
        const newAptStartIsBeforeCurrentAptStart: boolean = decimalStartOfAptToCreate < currentAptDecimalStart;
        const newAptEndIsAfterCurrentAptStart: boolean = decimalEndOfAptToCreate > currentAptDecimalStart;
        const newAptStartIsBeforeCurrentAptEnd: boolean = decimalStartOfAptToCreate < currentAptDecimalEnd;
        if (bothAptHasSameEnd && bothAptHasSameStart) return false;
        if (newAptEndIsAfterCurrentAptStart && newAptStartIsBeforeCurrentAptStart) return false;
        if (newAptStartIsBeforeCurrentAptEnd && newAptEndIsAfterCurrentAptEnd) return false;
    }

    return true;
}

/**
 * Retoune la bonne couleur de fond pour le nom du client
 * @param apt
 */
const getClientNameBackground = (apt: Appointment): string => {
    let clientNameBg: ClientNameBackground = apt.drawing_done ?
        ClientNameBackground.APT_DRAWING_DONE : ClientNameBackground.APT_DRAWING_NOT_DONE;
    if (apt.appointment_type !== AptType.appointment) {
        clientNameBg = ClientNameBackground.NOT_APT;
    }
    return clientNameBg;
}

/**
 * Renvoie une AppointmentCellPosition à partir du RDV. C'est ici qu'est calculé la position du RDV sur la grille dans
 * l'IHM
 * @param appointment
 */
const getCellPositionFromAppointment = (appointment: Appointment): AppointmentCellPosition => {
    const includedStartRow: number = store.getState().week.workingHours.indexOf(DateTool.getDecimalHourAndMinutes(appointment.date_start)) + ROW_INCLUDE_OFFSET_FOR_WORKING_HOURS_INDEX;
    const excludedEndRow: number = store.getState().week.workingHours.indexOf(DateTool.getDecimalHourAndMinutes(appointment.date_end)) + 2;
    const column: number = moment(new Date(appointment.date_start)).weekday() + 1; // + 1, car la premiere colonne est celle des heures
    return {
        startRow: includedStartRow, // Case de départ
        endRow: excludedEndRow, // Case de fin non comprise
        column, // Colonne
        gridArea: `${includedStartRow} / ${column} / ${excludedEndRow}`
    }
}

const WeekViewTool = {
    getCellPositionFromEvent,
    getClientNameBackground,
    isThereEnoughSpaceFor,
    getCellPositionFromAppointment,
    getAppointmentByColumnAndRow,
    isLunchtime
}

export default WeekViewTool;