export const stringToColour = function (str: string) {
    let hash = 9; // 4 pas mal aussi
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 17)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}