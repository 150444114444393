import {configureStore} from '@reduxjs/toolkit'
import authReducer from "./reducers/authSlice";
import weekReducer from "./reducers/weekSlice";
import appReducer from "./reducers/appSlice";

const reducer = {
    auth: authReducer,
    week: weekReducer,
    app: appReducer,
}

const store = configureStore({
    reducer,
})
export default store;