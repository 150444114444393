import './Menu.css';
import React, {FC} from "react";
import 'react-toastify/dist/ReactToastify.css';
import {MenuItemType} from "./enums/EMenuItemType";
import {MenuProps} from "./interfaces/IMenuProps";

const Menu: FC<MenuProps> = ({position, items, onClose}) => {

    /**
     * Affiche les items du menu
     */
    const displayItems = () => {
        let menuItems = [];
        for (const item of items) {
            if (item.displayCondition) {
                if (item.type === MenuItemType.ITEM) {
                    menuItems.push(
                        <li className={"disableTextSelection"} key={items.indexOf(item)} style={item.style}
                            onClick={() => {
                                if(item.action) item.action();
                                onClose();
                            }}>{item.text}</li>
                    );
                }
                if (item.type === MenuItemType.SEPARATOR) {
                    menuItems.push(<hr key={items.indexOf(item)}/>)
                }
            }

        }
        return menuItems
    }

    /**
     * Gère le décalage du menu si au bord de l'écran
     */
    function getPosition(): { top: number, left: number, width: number } {
        // Si click à plus de "bord de l'écran" - width => on décale de -width
        const width = 110;
        const body = document.querySelector("body");
        return body && position.x > body.getBoundingClientRect().right - width ?
            {top: position.y, left: position.x - width, width}
            :
            {top: position.y, left: position.x, width}
    }

    return (
        <>
            <ul className={"menu disableTextSelection"} style={getPosition()}>
                {displayItems()}
            </ul>
        </>
    );

};

export default Menu;