import React, {ReactElement} from 'react';
import './AppointmentForm.css';
import Appointment from "../../interfaces/Appointment";
import moment from "moment";
import 'react-toastify/dist/ReactToastify.css';
import {AptType} from "../../enums/AptType";
import DateTool from "../../utils/DateTool";

export const generateIndispoObject = (tattoo_artist_id: number, date_start: string, date_end: string): Appointment => {
    return {
        tattoo_artist_id,
        firstname: "",
        lastname: "",
        phone: "",
        date_start,
        date_end,
        price: 0,
        arrhes: 0,
        pattern: "",
        comment: "",
        appointment_type: AptType.unavailable,
        is_deleted: false,
        email: "",
        total_price: 0,
        duration: 0,
        start: 0,
        end: 0
    }
}

export function getDefaultStartingHour(apt: Appointment, workingHours: number[]): number {
    return workingHours.includes(DateTool.getHour(apt.date_start)) ? DateTool.getHour(apt.date_start) : workingHours[0];
}

export function getDefaultEndingHour(apt: Appointment, workingHours: number[]): number {
    return workingHours.includes(DateTool.getHour(apt.date_end)) ? DateTool.getHour(apt.date_end) : workingHours[workingHours.length - 1];
}

export function getDefaultStartingMinutes(apt: Appointment): string {
    return ["00", "30"].includes(moment(apt.date_start).format("mm")) ? moment(apt.date_start).format("mm") : "00";
}

export function getDefaultEndingMinutes(apt: Appointment): string {
    return ["00", "30"].includes(moment(apt.date_end).format("mm")) ? moment(apt.date_end).format("mm") : "00";
}

export const displayStartHours = (workingHours: number[]): ReactElement[] => {
    let rows = [];
    for (let hour of workingHours) {
        if (Number.isInteger(hour)) {
            rows.push(<option key={`start-${workingHours.indexOf(hour)}`} value={`${hour}`}>{hour}</option>);
        }
    }
    return rows;
}


export const displayEndHours = (workingHours: number[]): ReactElement[] => {
    let rows = [];
    for (let hour of workingHours) {
        if (Number.isInteger(hour)) {
            rows.push(<option key={`end-${workingHours.indexOf(hour)}`} value={`${hour}`}>{hour}</option>);
        }
    }
    return rows;
}

/**
 * Vérifie si les heures d'un RDV sont en dehors des heures d'ouverture.
 * @param apt
 * @param workingHours
 */
export const isThereSomethingWrongWithThisAptHours = (apt: Appointment, workingHours: number[]): boolean => {
    return !workingHours.includes(DateTool.getDecimalHourAndMinutes(apt.date_start)) ||
        !workingHours.includes(DateTool.getDecimalHourAndMinutes(apt.date_end));
}

export const getAppointmentDateTime = (dateString: string, hour: number, minutes: number): string => {
    // Pour enlever tout ce qui est après la date et éviter les jours de décalage à cause de momentjs
    dateString = dateString.substring(0, 10);
    let m;
    if (moment(dateString, "DD/MM/YYYY").isValid()) {
        m = moment(dateString, "DD/MM/YYYY")
    } else {
        m = moment(dateString);
    }

    return m.set("hour", hour).set("minute", minutes).format("YYYY-MM-DD HH:mm:00");
}