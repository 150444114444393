import React, {FC} from 'react';

import './Loader.scss';
import {Rings} from "react-loader-spinner";

type Props = {
    message: string
}
const Loader: FC<Props> = ({message}) => {
    return (
        <>
            <div className={"loader-box"}>
                <Rings color={"#ffffff"} />
                {message}
            </div>
        </>
    )
}

export default Loader;