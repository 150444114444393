import {createSlice} from '@reduxjs/toolkit'
import WeekUtils from "../../utils/WeekUtils";

type IInitState = {
    weekStart: string,
    workingHours: number[]
}
const initialState : IInitState= {
    weekStart: new WeekUtils().getCurrentWeekStart(),
    workingHours : []
}

export const weekSlice = createSlice({
    name: 'week',
    initialState,
    reducers: {
        setWeekStart: (state, action) => {
            state.weekStart = action.payload
        },
        setWorkingHours: (state, action) => {
            state.workingHours = action.payload
        },

    },
})

// Action creators are generated for each case reducer function
export const {setWeekStart, setWorkingHours} = weekSlice.actions
const weekReducer = weekSlice.reducer
export default weekReducer;