export enum Wording {
    TOAST_CANNOT_AVAILABLE_WEEK = "Des rendez-vous sont prévus cette semaine.\nSupprimez-les, puis réessayez.",
    TOAST_FEATURE_INCOMING = "Cette fonctionnalité arrive bientôt",
    TOAST_OUPS = "Oups ! Quelque chose s'est mal passé.",
    TOAST_MESSAGE_SENT = "Message envoyé",
    TOAST_ARTIST_ACTIVATED = "Artiste activé",
    TOAST_WRONG_CREDENTIALS="Identifiants invalides",
    TOAST_MISSING_CREDENTIALS="Identifiant et/ou mot de passe non fourni(s)",
    TOAST_ARTIST_DEACTIVATED="Artiste désactivé",
    TOAST_APPOINTMENT_SAVED="Rendez-vous enregistré",
    TOAST_APPOINTMENT_MODIFIED="Rendez-vous modifié",
    TOAST_ARTIST_CREATED="Artiste créé avec succès",
    TOAST_SIMULATION_DONE="Simulation terminée",
    TOAST_INDISPO_SET="Indispo posée",
    TOAST_NOT_SPACE_AVAILABLE="Espace insuffisant",
    TOAST_APPOINTMENT_COPIED="Rendez-vous copié",
    TOAST_APPOINTMENT_PASTED="Rendez-vous collé",
    TOAST_APPOINTMENT_CUT="Rendez-vous coupé",
    TOAST_APPOINTMENT_DELETED="Rendez-vous supprimé",
    TOAST_OLD_APPOINTMENT_DELETED="Ancien rendez-vous supprimé",
    WRONG_SIMULATION_DATE_FORMAT="Le mois choisi n'est pas au bon format. Le format souhaité est le suivant : 2000-01",
    BUTTON_GENERATE_REPORT="Générer le rapport",
    ENTER_NEW_MESSAGE="Saisissez un nouveau message",
    SEND_BUTTON="Envoyer",
    WEEK_UNAVAILABLE="Semaine indispo",
    SIMULATION_RUNNING="Simulation en cours",
    TOAST_TEL_NUMBER_COPIED="Numéro de téléphone copié",
    TOAST_PASSWORD_DIFFERENT="Les mots de passe ne sont pas identiques",
    TOAST_CREDENTIALS_UPDATED="Identifiants modifiés avec succès",
    TOAST_PASSWORD_TOO_SHORT="Mot de passe trop court",
}
