import {API_ENDPOINTS_URI} from "./ApiService";
import {RequestResponse} from "../types/RequestResponse";
import api, {emptyRequestResponse} from "./api";
import {AptRequest} from "../interfaces/request/AptRequest";
import Appointment from "../interfaces/Appointment";
import {DeleteAptRequest} from "../interfaces/request/DeleteAptRequest";
import SyncRequest from "../interfaces/request/SyncRequest";

async function postAppointment(permission: number, apt: Appointment): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;
    const request: AptRequest = {
        permission,
        apt
    }
    return api.post(API_ENDPOINTS_URI.POST_APT, request)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })

}

async function deleteAppointment(permission: number, appointmentId: number): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;
    const request: DeleteAptRequest = {
        permission,
        appointmentId
    }
    return api.post(API_ENDPOINTS_URI.DELETE_APT, request)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })

}

async function sync(syncMonth: string, formData: any): Promise<RequestResponse> {
    let response: RequestResponse = emptyRequestResponse;
    let request: SyncRequest = {month: syncMonth, datas: []}
    for (let key of Object.keys(formData)) {
        // @ts-ignore
        request.datas.push({
            artist: formData[key].artist,
            checked: formData[key].checked,
            wanted: formData[key].wanted
        })
    }
    return api.post(API_ENDPOINTS_URI.SYNC, request)
        .then((res: any) => {
            response.hasFailed = false;
            response.data = res.data;
            return response;
        })
        .catch((err: any) => {
            response.hasFailed = true;
            response.errorMessage = err.message;
            response.errorStatus = err.response.status;
            return response
        })

}

const MessageService = {
    postAppointment,
    deleteAppointment,
    sync
}

export default MessageService;