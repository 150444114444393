import React, {useEffect, useState} from 'react';
import "./CredentialUpdate.scss";
import 'react-toastify/dist/ReactToastify.css';
import {Permission} from "../../enums/Permission";
import {cToast, ToastType} from "../../utils/CustomToaster";
import {Wording} from "../../enums/Wording";
import {UpdateCredentialsRequest} from "../../interfaces/request/UpdateCredentialsRequest";
import UserService from "../../services/UserService";
import {Username} from "../../interfaces/request/Username";


const CredentialUpdate = (_: any) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [userPermission, setUserPermission] = useState(Permission.user);
    const [users, setUsers] = useState<Username[]>([]);

    useEffect(() => {
        UserService.getUsernames()
            .then(res => {
                const userList = res.data.result;
                setUsers(userList)
                setUsername(userList[0].username)
            })
            .catch(_ => {
                cToast(Wording.TOAST_OUPS, ToastType.ERROR)
            })
    }, [])

    function getSchedulerLabel(userPermission: Permission) {
        let label: string;
        switch (userPermission) {
            case Permission.user:
                label = "Agenda quotidien"
                break;
            case Permission.simulation:
                label = "Agenda simulé"
                break;
            case Permission.admin:
                label = "Compte admin"
                break;
            default:
                label = ""
        }
        return label;
    }

    const saveCredential = () => {
        if (password !== passwordConfirm) {
            return cToast(Wording.TOAST_PASSWORD_DIFFERENT, ToastType.ERROR);
        }
        if (password.length < 6) {
            return cToast(Wording.TOAST_PASSWORD_TOO_SHORT, ToastType.ERROR);
        }
        const request: UpdateCredentialsRequest = {
            permission: userPermission.valueOf(),
            username,
            password
        }
        UserService.updateCredentials(request).then(_ => {
            return cToast(Wording.TOAST_CREDENTIALS_UPDATED, ToastType.SUCCESS);
        }).catch(_ => {
            return cToast(Wording.TOAST_OUPS, ToastType.ERROR);
        })
    }

    function handleSelectedPermission(permission: Permission, uname: string) {
        setUserPermission(permission);
        setUsername(uname);
        setPassword("");
        setPasswordConfirm("");
    }

    return (
        <div className="form-container w-40 margin-auto">
            <div className="form-box">
                <h3>Modification des identifiants</h3>
                <select className="select" style={{width: "100%"}}>
                    {users.map(user => (
                        <option key={user.permission} value={user.permission}
                                onClick={() => handleSelectedPermission(user.permission, user.username)}>
                            {getSchedulerLabel(user.permission)}
                        </option>
                    ))}
                </select>

                <input
                    type="text"
                    value={username}
                    onChange={(e: any) => setUsername(e.target.value)}
                    placeholder="Identifiant"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    placeholder="Mot de passe"
                />
                <input
                    type="password"
                    value={passwordConfirm}
                    onChange={(e: any) => setPasswordConfirm(e.target.value)}
                    placeholder="Confirmez le mot de passe"
                />

                <button className="btn" onClick={saveCredential}>Envoyer</button>
            </div>
        </div>
    );
};

export default CredentialUpdate;
