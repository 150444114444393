/**
 * Retourne le nombre de demi-heures entre deux datetime
 * @param a
 * @param b
 */
const getNumberOfHalfHoursBetweenDates = (a: string, b: string): number => {
    const decimalA = getDecimalHourAndMinutes(a);
    const decimalB = getDecimalHourAndMinutes(b);

    if (decimalA - decimalB === 0) {
        return 0;
    } else if (decimalA > decimalB) {
        return (decimalA - decimalB) * 2
    } else {
        return (decimalB - decimalA) * 2;
    }
}

/**
 * Retourne la valeur décimale d'une date. Ex : "2022-07-18T10:00:00.000Z" => 10 / "2022-07-18T10:30" => 10.5
 * @param date
 */
const getDecimalHourAndMinutes = (date: string) => getHour(date) + (getMinutes(date) === 30 ? 0.5 : 0);

/**
 * Transforme une date à problème type "2022-07-18T10:00:00.000Z" (renvoyée par le back)
 * au format "2022-07-18 10:00"
 * @param str
 */
const simplifyDateString = (str: string): string => str.replace(".000Z", "").replace("T", " ");

/**
 *  Retourne l'heure depuis une date en String au format "2022-12-12T19:00:00.000Z" => 19
 * @param str
 */
const getHour = (str: String) : number => Number(str.substring(str.indexOf(":") - 2, str.indexOf(":")));

/**
 *  Retourne les minutes depuis une date en String au format "2022-12-12T19:00:00.000Z" => 0
 * @param str
 */
const getMinutes = (str: String) : number => Number(str.substring(str.indexOf(":") + 1, str.indexOf(":") + 3));

const DateTool = {
    getNumberOfHalfHoursBetweenDates,
    getDecimalHourAndMinutes,
    simplifyDateString,
    getHour, getMinutes
}

export default DateTool;