import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {API_URL} from "../settings";
import store from "../redux/store";
import TokenService from "./TokenService";
import {StatusCodes} from "http-status-codes";
import {API_ENDPOINTS_URI} from "./ApiService";
import {RequestResponse} from "../types/RequestResponse";

const instance: AxiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config: AxiosRequestConfig<any>) => {
        const token: string = TokenService.getAccessToken();
        if (token) {
            // @ts-ignore
            config.headers["Authorization"] = store.getState().auth.accessToken;  // for Spring Boot back-end
        }
        return config;
    },
    (error: any) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;
        if (originalConfig.url !== API_ENDPOINTS_URI.AUTH_LOGIN && err.response) {
            TokenService.add401();
            if (err.response.status === StatusCodes.UNAUTHORIZED && !TokenService.isThereMoreThanOne401()) {
                try {
                    try {
                        const rs = await instance.post(API_ENDPOINTS_URI.AUTH_REFRESH_TOKEN, {
                            refreshToken: TokenService.getRefreshToken(),
                        });
                        const {accessToken} = rs.data;
                        TokenService.noMore401();
                        TokenService.updateLocalAccessToken(accessToken);
                        return instance(originalConfig);
                    } catch (e: any) {
                        TokenService.noMore401();
                        TokenService.setAccessToken("");
                        return Promise.reject(e);
                    }

                } catch (_error) {
                    TokenService.noMore401();
                    TokenService.setAccessToken("");
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);
export default instance;

export const emptyRequestResponse : RequestResponse = {
    hasFailed: true,
    errorMessage: "",
    errorStatus: 0,
    data: null
}