let API_DEST;
const local = 'http://localhost:3017'
switch (process.env.NODE_ENV) {
    case 'production':
        // @ts-ignore
        API_DEST = window.env.API_ENDPOINT || local;
        break;
    case 'development':
    default:
        API_DEST = local;
        break;
}

export const API_URL = API_DEST;