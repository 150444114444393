import {createSlice} from '@reduxjs/toolkit'
import User from "../../interfaces/User";

type IInitState  = {
    accessToken: string,
    refreshToken: string,
    user: User,
    numberOf401: number
}
const initialState : IInitState= {
    user: {
        id: 0,
        pseudo: "string",
        email: "string",
        permission: 0
    },
    accessToken: "",
    refreshToken: "",
    numberOf401: 0,
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload
        },
        setAccessToken: (state, action) => {
            state.accessToken = action.payload
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload
        },
        logout: (state) => {
            state.accessToken = ""
        },
        add401(state) {
            state.numberOf401 += 1
        },
        reset401(state) {
            state.numberOf401 = 0
        }
    },
})

// Action creators are generated for each case reducer function
export const {setUser, setAccessToken, logout, setRefreshToken, add401, reset401} = authSlice.actions
const authReducer = authSlice.reducer
export default authReducer;