import {createSlice} from '@reduxjs/toolkit'
import User from "../../interfaces/User";
import Artist from "../../interfaces/Artist";
import Appointment from "../../interfaces/Appointment";

type TInitialState = {
    user: User | {},
    artists: Artist[],
    currentArtist: Artist | null,
    copy: Appointment | null;
    copyIsCut: boolean;
    backendVersion: String;
}
const initialState: TInitialState = {
    user: {},
    artists: [],
    currentArtist: null,
    copy: null,
    copyIsCut: false,
    backendVersion: ""
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user += action.payload
        },
        setArtists: (state, action) => {
            state.artists = action.payload
        },
        setCurrentArtist: (state, action) => {
            state.currentArtist = action.payload
        },
        setCopy: (state, action) => {
            state.copy = action.payload
        },
        setCopyIsCut: (state, action) => {
            state.copyIsCut = action.payload
        },
        setBackendVersion: (state, action) => {
            state.backendVersion = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const {setUser, setArtists, setCurrentArtist, setCopy, setCopyIsCut, setBackendVersion} = appSlice.actions
const appReducer = appSlice.reducer
export default appReducer;