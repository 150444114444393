import React, {FC} from "react";
import {AppointmentCellProps} from "./interface/AppointmentCellProps";
import WeekViewTool from "../../pages/WeekView/WeekViewTool";
import './AppointmentCell.scss';

const AppointmentCell: FC<AppointmentCellProps> = (
    {
        uniqueKey,
        apt,
        onAptMouseDown,
        onAptTouchStart,
        onAptClick,
        onAptContextMenu,
        onAptMouseUp,
        onAptMouseLeave,
        onAptTouchEnd
    }) => {
    const gridArea = WeekViewTool.getCellPositionFromAppointment(apt).gridArea;

    return (
        <div
            key={"appointmentCell" + uniqueKey}
            className={`aptCell ${apt.appointment_type}`}
            style={{gridArea: gridArea}}
            onMouseDown={e => onAptMouseDown(e)}
            onTouchStart={e => onAptTouchStart(e)}
            onContextMenu={e => onAptContextMenu(e)}
            onClick={e => onAptClick(e)}
            onMouseUp={e => onAptMouseUp(e)}
            onMouseLeave={e => onAptMouseLeave(e)}
            onTouchEnd={e => {
                onAptTouchEnd(e)
                onAptClick(e)
            }}
        >

            {apt.firstname &&
                <div className={"firstnameOnCell"}
                     style={{backgroundColor: `${WeekViewTool.getClientNameBackground(apt)}`}}>
                    {apt.firstname[0].toUpperCase() + apt.firstname.substring(1).toLowerCase()}
                </div>}
            <>
                {apt.duration > 1 && <div className={"patternOnCell"}><em>{apt.pattern}</em></div>}
                {apt.duration > .5 && <div><small className={"phoneOnCell"}>{apt.phone}</small></div>}
            </>
        </div>
    )
}

export default AppointmentCell;