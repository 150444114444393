import moment from "moment";
import {Day, Week} from "../types/Week";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DB_DATE_FORMAT = "YYYY.MM.DD";
export const WEEK_DAYS: any = {
    0: "Dimanche",
    1: "Lundi",
    2: "Mardi",
    3: "Mercredi",
    4: "Jeudi",
    5: "Vendredi",
    6: "Samedi"
};
export const MONTH_NAMES: string[] = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];

class WeekUtils {
    private getThreeFirstLettersOfWeekDay = (dateStr: string) => WEEK_DAYS[moment(dateStr).day()].substring(0, 3);

    public addOneWeek = (initialDate: string) => moment(initialDate).add(7, "days").format(DATE_FORMAT);
    public substractOneWeek = (initialDate: string) => moment(initialDate).subtract(7, "days").format(DATE_FORMAT);
    public getCurrentWeekStart = () => moment().startOf("isoWeek").format(DATE_FORMAT);
    public getSchedulerFormattedHours = (hour: number): string => {
        let str;
        if (Number.isInteger(hour)) {
            str = hour < 10 ? `0${hour}h00` : `${hour}h00`
        } else {
            str = hour < 10 ? `0${hour - 0.5}h30` : `${hour - 0.5}h30`
        }
        return str
    };
    public getStrHourForDB = (hour: number) => {
        const str = this.getSchedulerFormattedHours(hour)
        return str.replace("h", ":") + ":00"
    };

    public getTimeStamp = (dateStr: string) => moment(dateStr).valueOf();
    public getDBDate = (dateStr: string) => moment(dateStr).format(DB_DATE_FORMAT);
    public getTotalPrice = (d: Day) => {
        let total = 0;
        if (d.appointments) {
            for (const a of d.appointments) {
                if (a.price) {
                    total += a.price
                }
            }
        }
        return total
    };

    public getWeekTotalPrice = (w: Week) => {
        let total = 0;
        for (let d of w.days) {
            total += this.getTotalPrice(d);
        }
        return total;
    }

    public getDateStr = (dateStr: string): string => {
        return `${this.getThreeFirstLettersOfWeekDay(dateStr)}. 
        ${moment(dateStr).format("DD")} 
        ${MONTH_NAMES[+moment(dateStr).format("MM") - 1].length <= 4
            ? MONTH_NAMES[+moment(dateStr).format("MM") - 1]
            : MONTH_NAMES[+moment(dateStr).format("MM") - 1].substring(0, 4) + "."}`
    };
}

export default WeekUtils;