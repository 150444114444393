import React, {FC} from "react";
import "./ConfirmModal.css"

interface Props {
    message: string,
    onConfirm: Function,
    onClose: Function
}

const ConfirmModal: FC<Props> = ({message, onConfirm, onClose}) => {
    return (
        <div className={"confirm-modal"} onClick={() => onClose()}>
            <div onClick={e => e.stopPropagation()} className={"confirm-modal-content"}>
                <div className={"confirm-modal-header"}>
                    <h4 className={"confirm-modal-title"}>Suppression d'événement</h4>
                </div>
                <hr/>
                <br/>

                <br/>
                <div className={"confirm-modal-body"}>
                    {message}
                </div>
                <div className={"confirm-modal-footer"}>
                    <button onClick={() => onConfirm()} className={"btn delete-btn"}>Supprimer</button>
                    <button onClick={() => onClose()} className={"btn close-btn"}>Annuler</button>
                </div>
            </div>
        </div>
    )
}
export default ConfirmModal;