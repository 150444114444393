import store from "../redux/store";
import Artist from "../interfaces/Artist";
import {setArtists} from "../redux/reducers/appSlice";
import {RequestResponse} from "../types/RequestResponse";
import api from "./api";
import {API_ENDPOINTS_URI, handleRequestError} from "./ApiService";


/**
 * Asynchronously activates an artist and returns a `RequestResponse` object.
 *
 * @param {number} artistId ID of the artist to activate.
 * @returns {Promise<RequestResponse>} Promise resolving to the `RequestResponse` object.
 */
async function activateArtist(artistId: number): Promise<RequestResponse> {
    try {
        const response = await api.post(API_ENDPOINTS_URI.ACTIVATE_ARTIST, {artistId});
        return {hasFailed: false, data: response.data};
    } catch (error: any) {
        return handleRequestError(error);
    }
}


/**
 * Asynchronously creates a new artist and returns a `RequestResponse` object.
 *
 * @param {string} firstName Artist's first name.
 * @param {string} lastName Artist's last name.
 * @param {boolean} gender Artist's gender.
 * @returns {Promise<RequestResponse>} Promise resolving to the `RequestResponse` object.
 */
async function createArtist(firstName: string, lastName: string, gender: boolean): Promise<RequestResponse> {
    try {
        const response = await api.post(API_ENDPOINTS_URI.CREATE_ARTIST, {firstName, lastName, gender});
        return {hasFailed: false, data: response.data};
    } catch (error: any) {
        return handleRequestError(error);
    }
}

/**
 * Asynchronously retrieves a list of artists and returns a `RequestResponse` object.
 *
 * @returns {Promise<RequestResponse>} Promise resolving to the `RequestResponse` object.
 */
async function getArtists(): Promise<RequestResponse> {
    try {
        const response = await api.get(API_ENDPOINTS_URI.GET_ARTISTS); // Use GET for retrieving data
        return {hasFailed: false, data: response.data};
    } catch (error: any) {
        return handleRequestError(error);
    }
}

/**
 * Asynchronously deactivates an artist and returns a `RequestResponse` object.
 *
 * @param {number} artistId ID of the artist to deactivate.
 * @returns {Promise<RequestResponse>} Promise resolving to the `RequestResponse` object.
 */
async function deactivateArtist(artistId: number): Promise<RequestResponse> {
    try {
        const response = await api.delete(API_ENDPOINTS_URI.DEACTIVATE_ARTIST(artistId));
        return {hasFailed: false, data: response.data};
    } catch (error: any) {
        return handleRequestError(error);
    }
}

const ArtistService = {
    getArtists,
    createArtist,
    activateArtist,
    deactivateArtist,
    setArtists: (artists: Artist[]) => store.dispatch(setArtists(artists)),
    getArtistsFromStore: () => store.getState().app.artists,
}

export default ArtistService;