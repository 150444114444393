import store from "../redux/store";
import {add401, reset401, setAccessToken, setRefreshToken} from "../redux/reducers/authSlice";

const TokenService = {
    setAccessToken : (token : string) => store.dispatch(setAccessToken(token)),
    getAccessToken : () => store.getState().auth.accessToken,
    setRefreshToken : (token : string) => store.dispatch(setRefreshToken(token)),
    getRefreshToken : () => store.getState().auth.refreshToken,
    updateLocalAccessToken : (token: string) => store.dispatch(setAccessToken(token)),
    add401: () => store.dispatch(add401()),
    noMore401: () => store.dispatch(reset401()),
    getNumberOf401: () => store.getState().auth.numberOf401,
    isThereMoreThanOne401: () => store.getState().auth.numberOf401 > 1
}

export default TokenService;