import React, {FC, useState} from "react";
import './ArtistList.css';
import IArtists from "../../interfaces/Artist";
import Artist from "../../interfaces/Artist";
import {stringToColour} from "../../utils/uiUtils";
import {useDispatch} from "react-redux";
import {setCurrentArtist} from "../../redux/reducers/appSlice";
import store from "../../redux/store";
import {useNavigate} from "react-router-dom";
import {setWeekStart} from "../../redux/reducers/weekSlice";
import WeekUtils from "../../utils/WeekUtils";

interface Props {
}

const ArtistList: FC<Props> = () => {
    const weekUtils = new WeekUtils();
    const [artists, setArtists] = useState<IArtists[]>(store.getState().app.artists);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleClick = (a: Artist) => {
        dispatch(setCurrentArtist(a))
        store.dispatch(setWeekStart(weekUtils.getCurrentWeekStart()))
        navigate("/")
    }

    const displayArtists = () => {
        let row = []
        if (artists.length > 0) {
            for (let a of artists) {
                if (a.active) {
                    row.push(
                        <div key={`${a.firstname + a.firstname}-box`} onClick={() => handleClick(a)}>
                            <div key={`${a.firstname + a.firstname}-square`} className={`artist`}
                                 style={{backgroundColor: stringToColour(a.firstname + a.lastname)}}>
                                {a.gender ? <img src={require("../../assets/man.png")}/> :
                                    <img src={require("../../assets/woman.png")}/>}
                            </div>
                            <br/>
                            <small className={"artist-name"}
                                   key={`${a.firstname + a.firstname}-name`}>{a.firstname}</small>
                        </div>
                    )
                }
            }
        }
        return row
    }

    return (
        <div style={{minHeight: 350}} className={`artists`}>
            {displayArtists()}
        </div>
    )
}

export default ArtistList;